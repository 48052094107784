/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'usb-mini-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 3a1 1 0 00-1 1v1.293L.293 7A1 1 0 000 7.707V12a1 1 0 001 1h14a1 1 0 001-1V7.707A1 1 0 0015.707 7L14 5.293V4a1 1 0 00-1-1zm.5 5h9a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5"/>',
    },
});
